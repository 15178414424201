@import '../variables';
@import '../mixins';

.mobile-drawer-menu-overlay,
.mobile-drawer-menu-wrapper {
    position: fixed;
}

.mobile-drawer-menu-overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000, $alpha: 0.25);
    z-index: 900;
    opacity: 0;
    transition: opacity ease 0.12s;
    &.is-closing,
    &.is-closed {
        opacity: 0;
    }
    &.is-opening,
    &.is-opened {
        opacity: 1;
    }
}

.mobile-drawer-menu-wrapper {
    top: 0;
    right: 0;
    // width: 320px;
    width: 260px;
    height: 100vh;
    background-color: $white;
    z-index: 901;
    transform: translateX(100%);
    // transition: transform ease 0.25s, opacity ease 0.12s;
    transition: transform ease 0.25s;
    &.is-closing,
    &.is-closed {
        transform: translateX(100%);
    }
    &.is-opening,
    &.is-opened {
        transform: translateX(0);
    }
}

.mobile-drawer-menu {
    height: 100%;
    // .menu-header,
    // .menu-body {
    // }
    .menu-header {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid $border-color;
    }
    .user-avatar {
        min-width: 3.334rem;
        .icon {
            // font-size: 3.25rem;
            font-size: 3.334rem;
        }
        svg {
            display: block;
        }
        img {
            height: 3.334rem;
            width: auto;
        }
    }
    .user-general-info {
        word-break: break-all;
    }
    .menu-body {
        padding-top: 1rem;
    }
    .menu-item-link {
        display: block;
        text-transform: capitalize;
        color: $gray-800;
        padding: 0.875rem 1.5rem;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: inherit;
        }
    }
    .menu-item-link,
    .log-out-btn {
        font-size: 1.25rem;
        &:hover {
            opacity: 0.75;
        }
        &:active {
            opacity: 0.5;
        }
    }
    .menu-footer {
        padding: 1rem 1.5rem;
    }
    .log-out-btn {
        padding: 0.5rem 0.75rem;
        border: none;
        box-shadow: none;
        outline: none;
        .icon {
            font-size: 1.05em;
        }
    }
}
