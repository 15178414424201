@import '../variables';
@import '../mixins';

// body {
//     // background-color: rgba(6, 98, 178, 0.9);
//     background-color: rgba(90, 115, 211, 0.9);
// }


.login-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    padding: 0 8px;
}

@media (max-width: 767.98px) {
    .login-page-wrapper {
        padding-top: 3rem;
        padding-bottom: 18rem;
    }
}

.login-box-wrapper {
    position: relative;
    max-width: 800px;
    background-color: #fff;
    border-radius: $card-border-radius;
    // box-shadow: $boxes-shadow;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .languages-select-box {
        font-size: 1.05rem;
        // font-weight: 500;
        position: absolute;
        top: 1rem;
        left: 1.25rem;
        text-transform: uppercase;
        transition: opacity ease 0.2s;
        .lang-item {
            cursor: pointer;
            padding: 0.25rem;
            &.active {
                font-weight: 500;
            }
            &:not(.active) {
                opacity: 0.5;
            }
            &:not(:first-child) {
                margin-left: 0.5rem;
            }
        }
    }
    .simple-keyboard {
        &.hg-layout-default {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            // max-width: 260px;
            // height: 250px;
            // padding: 1.25rem 1.25rem;
            // border-radius: 1rem;
            // box-shadow: 0 0 3px 2px rgba(84, 84, 84, 0.25);
            // z-index: 100;
            position: absolute;
            bottom: 1rem;
            bottom: 0;
            left: 50%;
            left: calc(50% - 1px);
            transform: translateX(-50%);
            height: 250px;
            padding: 1.25rem 1.25rem;
            border-radius: 1rem;
            border-radius: 3px;
            // background-color: rgba(236, 236, 236, 0.9);
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 3px 2px rgba(84, 84, 84, 0.25);
            z-index: 100;
            &.mobile-keybord-theme {
                .hg-button {
                    // height: 2.95rem;
                    height: 100%;
                }
            }
        }
        &.hg-layout-mobile,
        &.hg-layout-mobileShift {
            .hg-standardBtn {
                max-width: 12%;
            }
        }
        .hg-rows {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .hg-row {
            flex-grow: 1;
        }
    }
    .pwa-install-prompt-btn {
        display: flex;
        align-items: center;
        position: absolute;
        top: 1.25rem;
        right: 1.5rem;
        .icon {
            font-size: 1.3rem;
            display: inline-block;
            margin-right: 0.35rem;
            vertical-align: middle;
        }
        svg {
            display: block;
        }
        @include mediaBreakEndPoint(849.98px) {
            padding: 0 !important;
            background-color: transparent !important;
            color: inherit !important;
            border: 0 !important;
            opacity: 0.75;
            transition: opacity ease 0.12s;
            text-decoration: underline;
            &:hover {
                opacity: 1;
            }
            // .icon {
            //     display: none;
            // }
        }
    }
}

@media (max-width: 767.98px) {
    .simple-keyboard {
        &.hg-layout-default {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            // background-color: #fdfdfd;
            background-color: $white;
            box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.22);
            border-radius: 2px;
            padding-top: 0.65rem;
            padding-bottom: 1rem;
            .hg-button {
                background-color: #ebebeb;
            }
        }
    }
}

.login-box-content {
    min-width: 320px;
    // max-width: 100%;
    padding: $boxes-padding-size;
    .logo-img {
        // width: 80px;
        width: 89px;
        height: auto;
    }
    .form-label {
        font-size: 1.1rem;
    }
    .submit-btn {
        position: relative;
        .btn-icon {
            font-size: 2rem;
            position: absolute;
            left: 1.25rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.login-box-banner {
    position: relative;
    &.banner-with-keyboard {
        .login-box-banner-image {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
        }
    }
    .banner-image {
        // @include mediaBreakEndPoint(767.98) {
        //     display: none;
        // }
        img {
            // height: 300px;
            height: 500px;
            width: auto;
            object-fit: cover;
            margin: 0.5rem 0.5rem 0.5rem 0;
            max-width: calc(435px - 0.5rem);
        }
        &::after,
        img {
            border-radius: 0 $card-border-radius $card-border-radius 0;
        }
    }
}

@media (min-width: 450px) {
    .login-box-content {
        min-width: 365px;
    }
}

// @media (max-width: 849.98px) {
@media (max-width: 767.98px) {
    .login-box-banner {
        .banner-image {
            display: none;
        }
    }
}

@media (min-width: 768px) and (max-width: 849.98px) {
    .login-box-banner {
        .banner-image {
            img {
                max-width: calc(100vw - 34rem);
                object-fit: cover;
            }
        }
    }
}

@media (min-width: 850px) {
    .login-box-content {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}


.form-control-wrapper {
    position: relative;
}


.toggleable-password {
    position: relative;
}

.toggleable-password .eye-btn {
    right: 1rem;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
}

.toggleable-password:not(.show-password) .eye-btn {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHN0cm9rZT0iIzkxOTFiMiIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgYXJpYS1oaWRkZW49InRydWUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMTMuODc1IDE4LjgyNUExMC4wNSAxMC4wNSAwIDAxMTIgMTljLTQuNDc4IDAtOC4yNjgtMi45NDMtOS41NDMtN2E5Ljk3IDkuOTcgMCAwMTEuNTYzLTMuMDI5bTUuODU4LjkwOGEzIDMgMCAxMTQuMjQzIDQuMjQzTTkuODc4IDkuODc4bDQuMjQyIDQuMjQyTTkuODggOS44OGwtMy4yOS0zLjI5bTcuNTMyIDcuNTMybDMuMjkgMy4yOU0zIDNsMy41OSAzLjU5bTAgMEE5Ljk1MyA5Ljk1MyAwIDAxMTIgNWM0LjQ3OCAwIDguMjY4IDIuOTQzIDkuNTQzIDdhMTAuMDI1IDEwLjAyNSAwIDAxLTQuMTMyIDUuNDExbTAgMEwyMSAyMSI+PC9wYXRoPjwvc3ZnPgo=');
}

.toggleable-password.show-password .eye-btn {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHN0cm9rZT0iIzkxOTFiMiIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgYXJpYS1oaWRkZW49InRydWUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMTUgMTJhMyAzIDAgMTEtNiAwIDMgMyAwIDAxNiAweiI+PC9wYXRoPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTIuNDU4IDEyQzMuNzMyIDcuOTQzIDcuNTIzIDUgMTIgNWM0LjQ3OCAwIDguMjY4IDIuOTQzIDkuNTQyIDctMS4yNzQgNC4wNTctNS4wNjQgNy05LjU0MiA3LTQuNDc3IDAtOC4yNjgtMi45NDMtOS41NDItN3oiPjwvcGF0aD48L3N2Zz4K');
}

.toggleable-password .form-control {
    padding-right: 3.67rem;
}

.toggleable-password .eye-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
