@import 'components/variables';

@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-Light.eot');
    src: local('Poppins Light'), local('Poppins-Light'),
        url('../public/fonts/poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/poppins/Poppins-Light.woff2') format('woff2'),
        url('../public/fonts/poppins/Poppins-Light.woff') format('woff'),
        url('../public/fonts/poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-Regular.eot');
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('../public/fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
        url('../public/fonts/poppins/Poppins-Regular.woff') format('woff'),
        url('../public/fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-Medium.eot');
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('../public/fonts/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
        url('../public/fonts/poppins/Poppins-Medium.woff') format('woff'),
        url('../public/fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-SemiBold.eot');
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('../public/fonts/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../public/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
        url('../public/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


html {
    font-size: $html-font-size;
}

body {
    font-family: Poppins, sans-serif;
    background-color: $bg-color;
    color: $primary;
}

.page-wrapper {
    overflow: hidden;
}

// #pwa-banner {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     background-color: #f8f8f8;
//     padding: 16px;
//     text-align: center;
//     box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
// }

// #pwa-banner a {
//     color: #333;
//     text-decoration: none;
// }

@media screen {
    body > #printSection {
        display: none;
    }
}

@media print {
    body {
        &.printing {
            background-color: $white;
            > *:not(#printSection) {
                display: none !important;
            }
            .text-info {
                color: #979797 !important;
            }
            .text-red {
                color: #1e1e1e;
            }
            .table > :not(caption) > * > * {
                background-color: transparent;
            }
        }
    }
    #printSection {
        width: 100vw;
        padding: 2rem 1.25rem;
        .underlined-subtitle {
            border-bottom-width: 0;
        }
    }
}

