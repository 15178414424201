$bg-color: #F8FAFB;

$pink: #EC82EF;
$pink-light: #FBF0FF;
// $pink-transparent: #EC82EF1A;

$purple: #6C5FFC;
$purple-light: #f0efff;
// $purple-transparent: #6C5FFC1A;

$red: #E82646;
$red-light: #fbf0ff;
// $red-transparent: #FF382B1A;

$yellow: #F7B731;
$yellow-dark: #e99f00;
// $yellow-light: #fff6e9;
// $yellow-transparent: #FFA22B1A;

$blue: #05C3FB;
$blue-light: #e6f9fe;
// $blue-transparent: #05C3FB1A;
// $blue-dark: #0583fb;

$green: #00E682;
$green-dark: #09AD95;
$green-light: #e5fbf2;
// $green-transparent: #00E6821A;

$orange: #FD794F;
$orange-light: #fef1ed;
// $orange-transparent: #FD794F1A;

$primary: #161E28;
$gray-800: #303030;
$gray-700: #586570;
$gray-600: #67768B;
$gray-500: #8F8FB1;
$gray-400: #b3b3b3;
$gray-300: #ddd;
$gray-200: #f9f9f9;
$gray-100: #fbfbfb;

$border-color: #E8E8F7;
// $border-color: #dbdbdb;
$gray-blue-light: #E8E8F7;
$white: #fff;

$options-hover-bg: rgba(237, 237, 247, 0.5);
$options-active-bg: rgba(237, 237, 247, 0.7);
$options-active-hover-bg: rgba(237, 237, 247, 0.95);

$html-font-size: 12px;

$box-title-fontSize: 1.167rem;
$box-subtitle-fontSize: 1.125rem;
$box-title-fontWeight: 500;
$box-title-lineHeight: 1.5;


$boxes-gap-size: 1rem;
$boxes-padding-size: 16px;

$boxes-shadow: 4px 6px 12px 0 rgba(27, 33, 44, 0.05);
$ddown-boxes-shadow: 0 6px 6px 0 rgba(27, 33, 44, 0.22);
$unattached-ddown-boxes-shadow: 0 2px 6px 0 rgba(27, 33, 44, 0.22);
$notification-boxes-shadow: 0 2px 6px 0 rgba(136, 151, 179, 0.22);


$blocks-unit-size: 40px;
$inline-items-space: 8px;


$border-radius-small: 4px;
$border-radius: 7px;
$card-border-radius: 8px;
$modal-border-radius-lg: 16px;


// $expanded-sidebar-width: 284px;
// $expanded-sidebar-menu-box-width: 256px;
// $collapsed-sidebar-width: 101px;
// $collapsed-sidebar-menu-box-width: 73px;


$multiStepProcessBox-sm-width: 302px;
// $multiStepProcessBox-lg-width: 464px;
$multiStepProcessBox-lg-width: 395px;
$multiStepProcessBox-xl-width: 395px;
$multiStepProcessBox-xxl-width: 512px;

$payBoxWithKeyboard-sm-width: 368px;
$payBoxWithKeyboard-lg-width: 480px;
// $payBoxWithKeyboard-xl-width: 504px;
$payBoxWithKeyboard-xl-width: 550px;
$payBoxWithKeyboard-xxl-width: 550px;

