@import '../variables';
@import '../mixins';

@keyframes leftIn {
    0% {
        // opacity: 0.85;
        transform: translateX(-100%);
    }
    100% {
        // opacity: 1;
        transform: translateX(0);
    }
}

.header-navbar {
    background-color: $bg-color;
    &.header-small {
        .navbar-inner {
            padding-top: 1.33rem;
            padding-bottom: 1.33rem;
        }
    }
    .navbar-inner {
        padding: 1.25rem $boxes-gap-size;
        transition: padding ease 0.25s;
    }
    .btn {
        height: $blocks-unit-size;
    }
    .icon-btn {
        font-size: 1.175rem;
        position: relative;
        padding: 0;
        width: calc($blocks-unit-size - 3px);
        height: calc($blocks-unit-size - 3px);
        // width: $blocks-unit-size;
        // height: $blocks-unit-size;
        border-radius: $border-radius;
        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        img {
            width: 20px;
            height: 20px;
        }
    }
    .btn {
        .btn-icon {
            vertical-align: middle;
        }
    }
    .select__control {
        min-height: $blocks-unit-size;
    }
    .header-circular-btn {
        padding: 0;
        width: calc(1.5em + 1.22rem + 2px);
        height: calc(1.5em + 1.22rem + 2px);
    }
    .d-down-btn {
        padding: 10px 18px 10px 12px;
        height: $blocks-unit-size;
        border-radius: $border-radius;
    }
    .dm-switch-btn {
        font-size: 1.22rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: $white;
        width: $blocks-unit-size * 0.8;
        height: $blocks-unit-size * 0.8;
        margin: $blocks-unit-size * 0.1;
        // background-color: $gray-400;
        background-color: lighten($color: $gray-400, $amount: 6);
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        transition: opacity ease-in 0.15s;
        &.active {
            // background-color: $green-dark;
            // background-color: darken($color: $green-dark, $amount: 1.25);
            background-color: #198754;
        }
        &.disabled,
        &:disabled {
            opacity: 0.5;
        }
        &.show-loader {
            .btn-text {
                scale: 0.9;
            }
            .loader-icon {
                opacity: 1;
            }
        }
        .loader-icon {
            font-size: 1.8em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity ease 0.2s;
            svg {
                display: block;
            }
        }
        .btn-text {
            transition: scale ease 0.2s;
        }
    }
    .pwa-install-prompt-btn {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        padding: 0.25rem 0.15rem;
        color: #08456a;
        text-align: left;
        border: none;
        box-shadow: none;
        opacity: 0;
        transition: opacity ease-in 0.25s;
        &.show-btn {
            opacity: 1;
        }
        .icon {
            display: inline-block;
            font-size: 2.2rem;
            vertical-align: middle;
            margin-right: 0.5rem;
            svg {
                display: block;
            }
        }
        .btn-text-wrapper {
            overflow: hidden;
        }
        .btn-text {
            display: block;
            transform: translateX(-100%);
            transition: transform 1s ease-out 0.25s;
        }
        &.show-text {
            .btn-text {
                transform: translateX(0);
            }
        }
        &:hover {
            color: #4a69be;
        }
        @include mediaBreakEndPoint (420px) {
            max-width: 10rem;
            padding: 0;
            .icon {
                font-size: 1.75rem;
            }
        }
    }
    .select2-selection {
        height: $blocks-unit-size !important;
        border: 0 !important;
        box-shadow: $boxes-shadow;
    }
    .select2-dropdown {
        border: 0 !important;
        box-shadow: $ddown-boxes-shadow !important;
        .opt-text {
            vertical-align: bottom;
        }
    }
    .navbar-language-select {
        position: relative;
        min-width: 125px;
        min-height: 1px;
        text-transform: uppercase;
        z-index: 1000;
        .select2-selection {
            min-width: inherit;
        }
        .opt-image {
            margin-right: 0.67rem !important;
        }
        .opt-text {
            vertical-align: bottom;
        }
    }
    .user-navbar-dropdown {
        display: inline-block;
        // cursor: pointer;
        // background-color: white;
        // border: 0!important;
        // box-shadow: 4px 6px 12px 0 rgb(27 33 44 / 6%);
        // border-radius: 10px;
        .dropdown-toggle {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: white;
            border: 0!important;
            box-shadow: 4px 6px 12px 0 rgb(27 33 44 / 6%);
            border-radius: 10px;
            padding: 0 0.5rem;
            color: $gray-600;
            &::after {
                // display: none;
                width: 0.925rem;
                height: 0.925rem;
                vertical-align: middle;
                border: none;
                margin: 0.2rem 0.25rem 0 0;
                mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDkxLjk5NiA0OTEuOTk2IiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGQ9Ik00ODQuMTMyIDEyNC45ODZsLTE2LjExNi0xNi4yMjhhMjYuNzIgMjYuNzIgMCAwIDAtMTkuMDMyLTcuODZjLTcuMjA4IDAtMTMuOTY0IDIuNzkyLTE5LjAzNiA3Ljg2bC0xODMuODQgMTgzLjg0OEw2Mi4wNTYgMTA4LjU1NGMtNS4wNjQtNS4wNjgtMTEuODItNy44NTYtMTkuMDI4LTcuODU2cy0xMy45NjggMi43ODgtMTkuMDM2IDcuODU2bC0xNi4xMiAxNi4xMjhjLTEwLjQ5NiAxMC40ODgtMTAuNDk2IDI3LjU3MiAwIDM4LjA2bDIxOS4xMzYgMjE5LjkyNGM1LjA2NCA1LjA2NCAxMS44MTIgOC42MzIgMTkuMDg0IDguNjMyaC4wODRjNy4yMTIgMCAxMy45Ni0zLjU3MiAxOS4wMjQtOC42MzJsMjE4LjkzMi0yMTkuMzI4YzUuMDcyLTUuMDY0IDcuODU2LTEyLjAxNiA3Ljg2NC0xOS4yMjQgMC03LjIxMi0yLjc5Mi0xNC4wNjgtNy44NjQtMTkuMTI4eiIvPjwvc3ZnPg==');
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: 93%;
                background-color: currentColor;
            }
            .user-name {
                position: relative;
                // padding-right: 1.65rem;
                padding-right: 0.65rem;
                // max-width: 9rem;
                max-width: 7rem;
                vertical-align: text-bottom;
                margin-left: 0.5rem;
                font-size: 1.17rem;
                font-weight: 500;
                // &:after {
                //     content: "";
                //     display: inline-block;
                //     position: absolute;
                //     right: 0;
                //     top: 50%;
                //     transform: translateY(-50%);
                //     width: 1.15rem;
                //     height: 1rem;
                //     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACsSURBVHgBhZAxDoIwFIZfKd6DOxgnFiaOAmwwSHFrmTSt7oZbOBMTTWTDM3gFT0DtMyHRSuUf2v9P+738eQBGCVM8rWQOM0rLfY5/0ZNssw304D8waADRyLKegjJ24BoGgd5fkID2t/NzFcbE5Mgc0TKM4d61Vxdk7vq4YyeKoe/aiwu2oUZWb0++Jyth6vKxtgceTEE/oA2PsiEUtcHP2i7or5K1KszqC9f7C/CLVsmWTyPtAAAAAElFTkSuQmCC');
                //     background-position: left center;
                //     background-repeat: no-repeat;
                //     background-size: auto;
                // }
            }
            .user-name-text {
                display: inline-block;
                width: 100%;
                vertical-align: middle;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .header-profile-img {
                width: auto;
                height: 34px;
                // border-radius: $border-radius;
                border-radius: 9px;
                padding: 2px;
                margin: 2px;
            }
        }
        .dropdown-menu {
            font-size: 1.167rem;
            // min-width: 15rem;
            min-width: 20rem;
            padding: 0.5rem;
            top: 0.25rem !important;
            box-shadow:
                rgb(255, 255, 255) 0px 0px 0px 0px,
                rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
            .dropdown-item {
                display: flex;
                align-items: center;
                color: #4b5563;
                height: 2.916rem;
                margin-bottom: 0.25rem;
                border-radius: 0.5rem;
                &:hover {
                    background-color: #f3f4f6;
                    color: #111827;
                }
            }
            .avatar-img {
                display: inline-block;
                height: 3.334rem;
                width: 3.334rem;
                min-width: 3.334rem;
                margin-right: 0.6rem;
            }
            .user-general-info {
                word-break: break-all;
            }
            .user-name {
                color: #111827;
                font-weight: 500;
            }
            .user-id {
                font-size: 1rem;
            }
            .icon {
                font-size: 1.833rem;
                // // line-height: 1.36;
                // line-height: 1.5;
                opacity: 0.5;
                margin-top: -0.25rem;
                margin-right: 0.6rem;
            }
        }
    }
    // .user-navbar-dropdown {
    //     top: calc(100% - 1px);
    //     width: 100%;
    //     // box-shadow: 0 2px 1px 0 rgb(27 33 44 / 22%) !important;
    //     box-shadow: 0 2px 6px 0 rgb(27 33 44 / 22%);
    //     box-shadow: 0 2px 6px 0 rgb(136 151 179 / 82%);
    //     border-radius: 0px 0px $border-radius $border-radius;
    //     box-shadow: 1px 4px 6px 0 rgba(27, 33, 44, 0.22) !important;
    //     .triangle-wrap {
    //         display: none !important;
    //     }
    //     .dropdown-item {
    //         padding: 8px 18px;
    //         font-size: 14px;
    //     }
    // }
}


.mobile-hamburger-btn {
    font-size: 2.35rem;
    border: 0;
    outline: none;
    box-shadow: none;
    // padding: 0;
    svg {
        display: block;
    }
}
